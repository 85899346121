<template>
  <v-row class="mt-2">
    <v-btn
      v-for="(type, index) in types"
      :key="index"
      class="mr-3 elevation-0"
      :class="{
        'teal white--text': value === type.value
      }"
      small
      rounded
      @click="select(type)"
    >
      {{ type.name }}
    </v-btn>
  </v-row>
</template>
<script>
export default {
  props:{
    value:{
      type:String,
      required:true
    },
    types:{
      type:Array,
      required:true
    }
  },
  methods:{
    select(type){
      this.$emit('input', type.value)
    }
  }

}
</script>