<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        class="elevation-0 mt-4"
        v-bind="attrs"
        :disabled="disabledView"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        Добавить товар
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in types"
        :key="index"
        @click="create(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    types: {
      required: true,
      type: Array
    },
    wareId: {
      required: true,
      type: Number
    },
  },
  computed:{
    ...mapGetters({
      disabledView:'zvt/getVisibility'
    })
  },
  methods: {
    create({value: type, createActionName}) {
      this.$store.dispatch(`zvt/${createActionName}`, {
        wareId: this.wareId,
        sortIndex: 1
      }).then((res) => {
        this.$emit("add", {type, item: res.data})
      }).catch(() => this.$error())
    },
  }
}
</script>